<template>
  <div class="common-layout">
    <el-container>
      <!--<el-aside width="200px">
        <el-card :body-style="{ padding: '10px' }" v-if="goods">
          <template #header>
            <div class="card-header">
              <span>商品详情</span>
            </div>
          </template>
          <img
            src="../../assets/images/placeholder.png"
            class="image"
          />
          <div style="padding: 14px">
            <span>商品型号 {{ goods.name }}</span>
            <div class="bottom">
              <time class="time">&yen;{{ goods.price/100 }}</time>-->
              <!--<el-button type="text" class="button">Operating</el-button>-->
            <!--</div>
          </div>
        </el-card>
      </el-aside>-->
      <el-main class="formbox">

          <el-steps style="margin-top: 100px" :active="active" align-center finish-status="success">
            <el-step title="录入信息" description="填充必要资料">
            </el-step>
            <el-step title="确认付款" description="选择付款渠道">
            </el-step>
            <el-step title="付款完成" description="Done"></el-step>
          </el-steps>

        <el-card v-if="active===0" class="box-card" style="width: 60%; margin: 50px auto">
          <template #header>
            <div class="card-header">
              <span>资料填充</span>
            </div>
          </template>

          <el-form
            ref="ruleDom"
            :model="ruleForm"
            :rules="rules"
            label-width="180px"
            class="demo-ruleForm"

          >
            <el-form-item label="购买主体" prop="buyBody">
              <el-input v-model="ruleForm.buyBody" placeholder="必填" maxlength="50"  show-word-limit></el-input>
              <p class="tit">注*所填内容需与签约主体一致</p>
            </el-form-item>
            <el-form-item label="购买数量" prop="buyNum">
              <el-input-number v-model="ruleForm.buyNum" size="small" :min="1" :max="500" />
              <p class="tit">注*购买数量多，直接修改购买数</p>
            </el-form-item>
            <el-form-item label="付款模式" prop="payModel">
              <el-select v-model="ruleForm.payModel" placeholder="请选择" @change="selectModel(ruleForm.payModel)" >
                <el-option label="购买主体银行付款" value="yes"></el-option>
                <el-option label="非购买主体银行付款" value="no"></el-option>
              </el-select>
            </el-form-item>
            <div v-if="enableOther === true">
              <el-form-item label="非购买主体付款人名称" prop="payerName" >
                <el-input v-model="ruleForm.payerName" placeholder="必填" maxlength="50"  show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="非购买主体付款人账号" prop="payAccount" v-if="enableOther">
                <el-input v-model="ruleForm.payAccount" placeholder="必填" maxlength="50"  show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="非购买主体付款人开户行" prop="payerBank" v-if="enableOther">
                <el-input v-model="ruleForm.payerBank" placeholder="必填" maxlength="50"  show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="非购买主体付款人手机号" prop="contactInfo">
                <el-input v-model="ruleForm.contactInfo" placeholder="必填" maxlength="11"  show-word-limit></el-input>
                <p class="tit">注*手机号用于订单查询和退款</p>
              </el-form-item>
            </div>
            <div v-if="enableOther === false">
              <el-form-item label="付款人账号" prop="payAccount">
                <el-input v-model="ruleForm.payAccount" placeholder="必填" maxlength="50"  show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="付款人开户行" prop="payerBank" else>
                <el-input v-model="ruleForm.payerBank" placeholder="必填" maxlength="50"  show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="付款人手机号" prop="contactInfo">
                <el-input v-model="ruleForm.contactInfo" placeholder="必填" maxlength="11"  show-word-limit></el-input>
              </el-form-item>
            </div>
            <el-form-item label="合同编号" prop="contractNo" class="contractNo">
              <el-input v-model="ruleForm.contractNo" placeholder="必填" maxlength="50"  show-word-limit></el-input>
                <p class="titlog">注*请务必按照合同信息填写真实合同编号</p>
            </el-form-item>
            <el-form-item label="代理商编号" prop="agentNo">
              <el-input v-model="ruleForm.agentNo" placeholder="非必填" maxlength="50"  show-word-limit></el-input>
              <!--<el-select v-model="ruleForm.agentNo" placeholder="请选择">-->
                <!--<el-option label="代理商01" value="ag01"></el-option>-->
                <!--<el-option label="代理商02" value="ag01"></el-option>-->
              <!--</el-select>-->
            </el-form-item>
            <el-form-item label="SN码" prop="sn">
              <el-input v-model="ruleForm.sn" placeholder="非必填" maxlength="50"  show-word-limit></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm()">提交</el-button>
            </el-form-item>

            <form
              ref="payDom"
              :action="payForm.action"
              name="b2b"
              method="post"
              target="_blank"
            >
              <!--<input type="hidden" name="epccGwMsg" id="epccGwMsg" v-model="payForm.epccGwMsg">-->
              <p v-html="payForm.epccGwMsg"></p >
            </form>
            <el-dialog style="border-bottom: 1px #eee solid" v-model="dialogFormVisible" title="选择方式">
              <el-radio-group v-model="ruleForm.channelType" style="text-align: center;">
                <el-radio style="height: 50px" label="unicomPay" border>
                  <img style="width: 126px; height: 43px" src="../../assets/img/unicompay.png" >
                </el-radio>
                <el-radio style="height: 50px" label="easyPay" border>
                  <img style="width: 126px; height: 43px" src="../../assets/img/easypay.png" >
                </el-radio>
              </el-radio-group>
              <template #footer>
                <span class="dialog-footer">
                  <!--<el-button @click="dialogFormVisible = false">取消</el-button>-->
                  <el-button type="primary" @click="btnLastComfirm()"
                  >确认</el-button
                  >
                </span>
              </template>
            </el-dialog>
          </el-form>
        </el-card>
        <el-card v-if="active===1" class="box-card" style="width: 830px; margin: 50px auto">
          <template #header>
            <div class="card-header">
              <span>选择付款方式</span>
            </div>
          </template>
          <template v-if="ruleForm">
            <el-descriptions title="主体信息">
              <el-descriptions-item label="购买主体">{{ruleForm.buyBody}}</el-descriptions-item>
              <el-descriptions-item label="付款人名称" v-if="enableOther">{{ ruleForm.payerName }}</el-descriptions-item>
              <el-descriptions-item label="付款人账号" v-if="enableOther">{{ ruleForm.payAccount }}</el-descriptions-item>
              <el-descriptions-item label="付款人账号开户行" v-if="enableOther">{{ ruleForm.payerBank }}</el-descriptions-item>
              <el-descriptions-item label="付款人手机号">{{ ruleForm.contactInfo }}</el-descriptions-item>

            </el-descriptions>
            <el-descriptions title="商品信息">
              <el-descriptions-item label="商品型号">{{ goods.name }}</el-descriptions-item>
              <el-descriptions-item label="合同编号">{{ ruleForm.contractNo }}</el-descriptions-item>
              <el-descriptions-item label="代理商编号">{{ ruleForm.agentNo }}</el-descriptions-item>
              <el-descriptions-item label="SN" v-if="ruleForm.sn">{{ ruleForm.sn }}</el-descriptions-item>
            </el-descriptions>

            <el-tabs type="border-card" @tab-click="tabClick">
              <el-tab-pane label="企业网银">
                <div v-if="b2bList">
                  <el-radio-group v-model="bankCode">
                    <el-radio :label="item.title" v-bind:key="key" v-for="(item, key) in b2bList">
                      <img v-if="item.title === bankCode" class="wx-checked" style="width: 140px; height: 40px" :src="item.img" >
                      <img v-else style="width: 140px; height: 40px" :src="item.img" >
                    </el-radio>
                  </el-radio-group>
                </div>
              </el-tab-pane>
              <el-tab-pane label="支付宝">
                <el-radio :label="alipay"  v-model="alipay" >
                  <img style="width: 108px; height: 42px" src="../../assets/images/zhifubao.jpg" >
                </el-radio>
              </el-tab-pane>
              <!--<el-tab-pane label="Role">Role</el-tab-pane>-->
            </el-tabs>


            <el-button style="margin-top: 30px" type="primary" @click="btnConfirm">确认付款</el-button>

            <!--<el-dialog style="border-bottom: 1px #eee solid" v-model="dialogFormVisible" title="选择方式">-->
                <!--<el-radio-group v-model="ruleForm.channelType" style="text-align: center;">-->
                  <!--<el-radio style="height: 50px" label="unicomPay" border>-->
                    <!--<img style="width: 126px; height: 43px" src="../../assets/img/unicompay.png" >-->
                  <!--</el-radio>-->
                  <!--<el-radio style="height: 50px" label="easyPay" border>-->
                    <!--<img style="width: 126px; height: 43px" src="../../assets/img/easypay.png" >-->
                  <!--</el-radio>-->
                <!--</el-radio-group>-->
              <!--<template #footer>-->
                <!--<span class="dialog-footer">-->
                  <!--&lt;!&ndash;<el-button @click="dialogFormVisible = false">取消</el-button>&ndash;&gt;-->
                  <!--<el-button type="primary" @click="btnLastComfirm()"-->
                  <!--&gt;确认</el-button-->
                  <!--&gt;-->
                <!--</span>-->
              <!--</template>-->
            <!--</el-dialog>-->

            <form
              ref="payDom"
              :action="payForm.action"
              name="b2b"
              method="post"
              target="_blank"
            >
              <input type="hidden" name="epccGwMsg" id="epccGwMsg" v-model="payForm.epccGwMsg">
                            <!--<p v-html="payForm.epccGwMsg"></p >-->
            </form>

            <el-dialog
              v-model="dialogVisible"
              :title="qrTitle"
              :before-close="handleClose"
              width="20%"
              customClass="customWidth"
            >
              <qrcode-vue :value='qrCodeUrl' :size='200' level='H' />
              <!--<template #footer>-->
              <!--<span class="dialog-footer">-->
              <!--<el-button @click="dialogVisible = false">Cancel</el-button>-->
              <!--<el-button type="primary" @click="dialogVisible = false"-->
              <!--&gt;Confirm</el-button>-->
              <!--</span>-->
              <!--</template>-->
            </el-dialog>
          </template>
        </el-card>

        <template v-if="active===2" >
          <el-result
            icon="error"
            title="付款失败了"
            sub-title="您可以重新下单付款哦"
          >
          </el-result>
        </template>
        <template v-if="active===3" >
          <el-result
            icon="success"
            title="付款成功"
            sub-title="可以通过我的订单，查询具体订单详情"
          >
            <template #extra>
              <el-button type="primary" size="medium" @click="goOrder">我的订单</el-button>
            </template>
          </el-result>
        </template>

      </el-main>
    </el-container>
  </div>
</template>

<script>
    import { ref, reactive, onBeforeUnmount } from "vue";
    import { findGoodsById, checkCustomer } from "@/api/home";
    import {useRouter} from "vue-router";
    import {alipayOrder, gateWayPayOrder, findOrderByTrace} from "../../api/home";
    import { ElMessage, ElMessageBox } from 'element-plus'
    import QrcodeVue from 'qrcode.vue'
    import ABC from '@/assets/images/bank/ABC.jpg'
    // import BOCOM from '@/assets/images/bank/BCM.jpg'
    import BOCSH from '@/assets/images/bank/BOC.jpg'
    import CCB from '@/assets/images/bank/CCB.jpg'
    import CMB from '@/assets/images/bank/CMB.jpg'
    import ICBC from '@/assets/images/bank/ICBC.jpg'
    // import PSBC from '@/assets/images/bank/PSBC.jpg'       //邮政银行
    import CMBC from '@/assets/images/bank/CMBC.jpg'     //民生银行
    import HXB from '@/assets/images/bank/HXB.jpg'       //华夏银行
    import PFB from '@/assets/images/bank/PFB.jpg'       //浦发银行
    import PABC from '@/assets/images/bank/PABC.jpg'       //平安银行
    import CITICIB from '@/assets/images/bank/CITICIB.jpg'       //中信银行
    // import CEB from '@/assets/images/bank/CEB.jpg'       //光大银行
    export default {
      name: "product_v2",
      components: { QrcodeVue },
      setup(props, {refs, root}) {
        const goods = ref(null);
        const dialogFormVisible = ref(false)
        const router = useRouter()
        let goodsId = router.currentRoute.value.params["id"];
        findGoodsById(goodsId).then(data => {
          console.log('goods detail ', data)
          goods.value = data.data
        });

        const validate = (rule, value, callback) => {
          //后台方法
          checkCustomer({customerName: value}).then(res => {
            console.log('res == ', res)
            if (res.code === 'success') {
              callback()
            } else if (res.code === 'failed') {
              callback('购买主体无效')
            }
          })
        }

        const active = ref(0)

        // 表单元素
        const ruleDom = ref()
        // 绑定数据
        const ruleForm = reactive({
            buyBody: '',
            buyNum: 1,
            payModel: '',
            payerName: '',
            payAccount: '',
            payerBank: '',
            contactInfo: '',
            contractNo: '',
            agentNo: '',
            sn:'',
            channelType:''
        })
        // 校验规则
        const rules = reactive({
          buyBody: [
            {
              required: true,
              message: '请输入购买主体',
              trigger: 'blur',
            },
            {
              min: 3,
              max: 50,
              message: '长度应该在3-50之间',
              trigger: 'blur',
            },
            // {validator: validate, trigger: 'blur'}
          ],
          payModel: [
            {
              required: true,
              message: '请选择付款模式',
              trigger: 'change',
            },
          ],
          payerName: [
            {
              required: true,
              message: '请输入付款人名称',
              trigger: 'blur',
            },
            {
              max: 50,
              message: '长度不能超过50',
              trigger: 'blur',
            },
          ],
          payAccount: [
            {
              required: true,
              message: '请输入付款人账号',
              trigger: 'blur',
            },
            {
              max: 50,
              message: '长度不能超过50',
              trigger: 'blur',
            },
          ],
          payerBank: [
            {
              required: true,
              message: '请输入开户行',
              trigger: 'blur',
            },
            {
              max: 50,
              message: '长度不能超过50',
              trigger: 'blur',
            },
          ],
          contactInfo: [
            {
              required: true,
              message: '请输入手机号',
              trigger: 'blur',
            },
            {
              min: 11,
              max: 11,
              message: '请输入11位手机号',
              trigger: 'blur',
            },
          ],
          contractNo: [
            {
              required: true,
              message: '请输入合同编号',
              trigger: 'blur',
            },
            {
              max: 50,
              message: '长度不能超过50',
              trigger: 'blur',
            },
          ],
          agentNo: [
            {
              max: 50,
              message: '长度不能超过50',
              trigger: 'blur',
            },
          ],
          sn: [
            {
              max: 50,
              message: '长度不能超过50',
              trigger: 'blur',
            },
          ],
        })
        const enableOther = ref(null)
        const selectModel = (val) => {
          console.log('select val ', val)
          if ( val === 'no') {
            enableOther.value = true
          } else {
            enableOther.value = false
          }
        }

        const submitForm = () => {
          console.log('sbf ', ruleForm)
          ruleDom.value.validate(valid => {
            console.log('校验结果', valid)
            if ( valid ) {
              dialogFormVisible.value = true
              // active.value = 1
            }
          })
        }
        // const resetForm = () =>{
        //   ruleDom.value.resetFields()
        // }
        const paymentType = ref('gateway_b2b')
        const tabClick = (tab, event) => {
          console.log('tab click ', tab.props.label)
          if (tab.props.label === '企业网银') {
            paymentType.value = 'gateway_b2b'
          } else if (tab.props.label === '支付宝') {
            paymentType.value = 'alipay_scan'
          }
        }

        const b2bList = reactive([
          {title:'ICBC', img: ICBC},
          {title:'ABC', img: ABC},
          {title:'BOCSH', img: BOCSH},
          {title:'CCB', img: CCB},
          {title:'CMB', img: CMB},
          {title:'CMBC', img: CMBC},
          {title:'HXB', img: HXB},
          {title:'SPDB', img: PFB},
          {title:'PAB', img: PABC},
          {title:'CNCB', img: CITICIB},
        ])
        // {title:'PSBC', img: PSBC},
        // {title:'CEB', img: CEB},
        const bankCode = ref('ICBC')
        const alipay = ref('alipay')

        // 补充部分
        const qrCodeUrl = ref('')
        const qrTitle = ref('')
        const dialogVisible = ref(false)

        const payDom = ref(null)
        const payForm = ref({action:'', epccGwMsg: ''})
        // const action = ref('')
        // const epccGwMsg = ref('')
        const btnLastComfirm = () => {
          // ruleForm.dialogFormVisible = false
          let _channelType = ruleForm.channelType
          console.log('pay channel ', _channelType)
          if (!_channelType) {
            console.log('渠道必填的')
            ElMessage({
              message: '请选一个渠道完成付款.',
              type: 'warning',
            })
            return
          }
          dialogFormVisible.value = false
          if ('easyPay' === _channelType) {
            active.value = 1
            return
          }
          // 组装报文
          let _tradeamt = goods.value.price * ruleForm.buyNum
          let postData = {...ruleForm, tradeamt: _tradeamt, goodsId, goodsModel: goods.value.name, body: goods.value.name, paymentType: paymentType.value}
          let b2bPostData = {...postData, amount: postData.tradeamt, transactionType: postData.paymentType, bankNo: bankCode.value, frontUrl: 'http://www.baidu.com'}
          console.log('last build post body ', b2bPostData)

          gateWayPayOrder(b2bPostData).then(data => {
            console.log('gateway_b2b feedback ', data)
            if (data.code === '1') {
              payForm.value.action = data.data.rdrctUrl
              payForm.value.epccGwMsg = data.data.formStr
              setTimeout(()=> {
                payDom.value.submit()
                getOrderstate(data.trace)
              }, 2000)

            } else {
              console.log('gateway_b2b error ', data.message)
              ElMessage({
                showClose: true,
                message: '出错了：'+ data.message,
                type: 'error',
              })
            }
          })
        }

        // easy pay
        const btnConfirm = ()=> {
          console.log('paymentType ', paymentType.value)
          console.log('bankCode ', bankCode.value)
          // 组装报文
          let _tradeamt = goods.value.price * ruleForm.buyNum
          console.log('商品单价', goods.value.price)
          console.log('购买数量', ruleForm.buyNum)
          console.log('付款总额', _tradeamt)
          let postData = {...ruleForm, tradeamt: _tradeamt, goodsId, goodsModel: goods.value.name, body: goods.value.name, paymentType: paymentType.value}
          console.log('build post body ', postData)

          // 付款
          switch (paymentType.value) {
            case 'gateway_b2b':
              let b2bPostData = {...postData, amount: postData.tradeamt, transactionType: postData.paymentType, bankNo: bankCode.value, frontUrl: 'http://www.baidu.com'}
              console.log('b2b post ', b2bPostData)
              // dialogFormVisible.value = true
              // console.log('dialogFormVisible ', dialogFormVisible.value)
              gateWayPayOrder(b2bPostData).then(data => {
                console.log('gateway_b2b feedback ', data)
                if (data.code === '1') {
                  payForm.value.action = data.data.rdrctUrl
                  payForm.value.epccGwMsg = data.data.prepayId
                  setTimeout(()=> {
                    payDom.value.submit()
                    getOrderstate(data.trace)
                  }, 2000)

                } else {
                  console.log('gateway_b2b error ', data.message)
                  ElMessage({
                    showClose: true,
                    message: '出错了：'+ data.message,
                    type: 'error',
                  })
                }
              })
              break
            case 'alipay_scan':
              alipayOrder(postData).then(data => {
                console.log('alipay_scan feedback ', data)
                if (data.code === '1') {
                  qrTitle.value = '支付宝支付'
                  qrCodeUrl.value =  data.data.resultContent
                  dialogVisible.value = true
                  // 开启定时监听
                  getOrderstate(data.trace)
                } else {
                  console.log('alipay_scan error ', data.message)
                  ElMessage({
                    showClose: true,
                    message: '出错了：'+ data.message,
                    type: 'error',
                  })
                }
              })
              break
            default:
              console.log('not found pay method')
          }
        }

        const handleChangeNum = (value) => {
          console.log('购买数量', value)
        }

        const goOrder = () => {
          router.push({
            path: '/order/search'
          })
        }
        const handleClose = (done) => {
          ElMessageBox.confirm('确定要退出付款吗?')
            .then(() => {
              done()
            })
            .catch(() => {
              // catch error
            })
        }
        const watchtimes = ref('')
        const getOrderstate = (trace)=>{
          let num = 0
          watchtimes.value = setInterval(()=>{ //创建一个全局的定时器
            num ++
            findOrderByTrace({trace: trace})
              .then(data => {
                console.log(data)
                if(data.code === '1' && data.data.status === 'success'){
                  console.log('ready route success')
                  active.value = 3
                  clearInterval(watchtimes.value) //别忘记关闭定时器，否则会一直调这个接口
                } else if(data.code === '1' && data.data.status === 'failure'){
                  console.log('ready route failure ')
                  active.value = 2
                  clearInterval(watchtimes.value)
                }})

            console.log("curr ", num, trace)
            if(num === 100){ //这里是判断num++到500的情况下用户还没有支付则自动关闭定时器和二维码
              dialogVisible.value = false
              clearInterval(watchtimes.value)
            }
          },1500)
        }
        onBeforeUnmount(() => {
          console.log('销毁定时器')
          clearInterval(watchtimes.value)
        })

        return { goods, active, dialogFormVisible, enableOther, ruleDom ,rules, ruleForm, selectModel, submitForm,
          tabClick, paymentType, b2bList, bankCode, alipay, btnConfirm, btnLastComfirm,
          goOrder,  dialogVisible,
          handleClose,
          qrTitle,
          qrCodeUrl,
          watchtimes,
          payDom, payForm }
      }
    }
</script>


  <style lang="less">
  .time {
    font-size: 13px;
    color: #999;
  }

  .bottom {
    margin-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button {
    padding: 0;
    min-height: auto;
  }
  .image {
    width: 100%;
    display: block;
  }
  .wx-checked {
    border: 2px solid #FFB302;
  }
  .el-radio__input{
    display: none;
  }

.el-input__inner{
    padding-left:10px;
    height:38px;
    line-height: 38px;
    border-radius:4px;
    border:1px solid #dcdfe6;
  }
.el-form-item__label{
  line-height: 38px;
  }
.el-select{
  display:inline;
}
 .el-button--primary{
    background-color: #ff6000;
    border-color: #ff3600;
}
.el-button--primary:focus, .el-button--primary:hover {
    background-color: #ff6000;
    border-color: #ff3600;
    color: #FFF;
}
.el-step__head.is-success{
  color:#ff6000;
  border-color: #ff3600;
}
.el-step__title.is-success,.el-step__description.is-success{
  color:#ff6000;
}
p.tit{
  color:#ff6000 !important;
  position:absolute;
  top:40px;
  line-height:20px;
  font-size:12px;
}
p.titlog{
  color:#ff6000 !important;
  position:absolute;
  top:40px;
  line-height:20px;
  font-size:12px;

}
.contractNo{
  height:90px;
}

.formbox .el-form-item{
  // margin-bottom:38px;
  height:50px;
  // float:left;
  width:85%;
}
// .el-form-item.contractNo{
//    height:90px;
// }
.card-header{
  font-weight:bold;
  font-size:15px;
}
.el-card__body{
  padding-top:36px;
  overflow:hidden;
}
.el-form-item__error{
  top: 40px;
  right:0;
  left:auto;
}
.el-card__body{
  overflow:hidden;
}
.el-form-item__content{
  min-width:230px;
}
.el-radio__label img{
  max-width:inherit;
}
.el-tabs__content .el-radio{
  margin-bottom:15px;
}
</style>
